// src/pages/Recruit.js
import { graphql } from 'gatsby';
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-cshtml';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-yaml';
import 'prismjs/themes/prism-twilight.css';
import React, { useEffect } from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import Layout from '../components/layout';
import Seo from '../components/seo';
import * as styles from './recruit.module.css';

const RecruitPage = ({ data }) => {
  const article = data.nanoCms.recruit;
  // prismjsの呼び出し
  useEffect(() => {
    // 全ての <code> タグを取得
    document.querySelectorAll('pre > code:not([class])').forEach((block) => {
      // classが未設定のものに対して、language-pythonを設定
      block.className = 'language-python';
    });
    Prism.highlightAll();
  }, []);
  useEffect(() => {
    const articleItem = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: '採用情報',
      image: '',
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(articleItem);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  });
  return (
    <Layout>
      <HeroSection imageSrc="/イタリア.jpg" />
      <section className={styles.serviceSectionPrimary} id="recruit">
        <div className={styles.serviceSectionInner}>
          <div className={styles.headContent}>
            <h2 className={styles.head}>
              <span className={styles.headText}>採用</span>
              <span className={styles.headSubtext}>Recruit</span>
            </h2>
            <div className={styles.cntWrapperIndex}>
              <div className={styles.mainnav}>
                <article className={styles.post}>
                  <div
                    className={styles.postContent}
                    dangerouslySetInnerHTML={{ __html: article.content }}
                  />
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RecruitPage;

export const Head = () => (
  <Seo title="採用情報" description="シェスタ株式会社の採用情報です。" />
);

export const query = graphql`
  query {
    nanoCms {
      recruit {
        content
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
