import React from 'react';
import * as styles from './HeroSection.module.css';

interface HeroSectionProps {
  title: string;
  subTitle?: string;
  description: string;
  imageSrc: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subTitle,
  description,
  imageSrc,
}) => {
  return (
    <div
      className={styles.heroSection}
      style={{ backgroundImage: `url(${imageSrc})` }}
    >
      <div className={styles.textSection}>
        <h1 className={styles.title}>{title}</h1>
        {subTitle ? <h2 className={styles.subTitle}>{subTitle}</h2> : ''}
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default HeroSection;
