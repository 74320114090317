// extracted by mini-css-extract-plugin
export var head = "recruit-module--head--c63e8";
export var headContent = "recruit-module--headContent--3c141";
export var headDetail = "recruit-module--headDetail--7ed21";
export var headSubtext = "recruit-module--headSubtext--61e63";
export var headText = "recruit-module--headText--7f040";
export var image = "recruit-module--image--08211";
export var imageContent = "recruit-module--imageContent--1948c";
export var imageText = "recruit-module--imageText--f8f96";
export var postContent = "recruit-module--postContent--03914";
export var serviceSectionGrid = "recruit-module--serviceSectionGrid--8f543";
export var serviceSectionInner = "recruit-module--serviceSectionInner--9ed54";
export var serviceSectionPrimary = "recruit-module--serviceSectionPrimary--8b6ed";
export var serviceSectionSecondary = "recruit-module--serviceSectionSecondary--1ba76";